import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UserInfo } from '../interfaces/user-info.model';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  
  public domain: string = '';

  subHeaderTitle = '';
  subHeaderDescription = '';

  companyid: number;
  tsid: number;
  headers;
  lp_apipHeaders;
  zapierHeaders;
  headersUpload;
  headersForm;
  sfHeaders;
  postmarkHeaders;
  httpOptions;
  userName: string;  

  sfToken: string;

  body = {
    "username": "lpadmin",
    "password": "Nts1234!"
   }

  constructor(private appsession: AppSessionService,  
    private http: HttpClient,
    ) {
    this.tsid = this.appsession.tsId;
    this.userName = this.appsession.user.userName;
    
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.headers = this.headers.set('Ocp-Apim-Trace', 'true');
    this.headers = this.headers.set('Ocp-Apim-Subscription-Key', '27036eaec9514423bd062f500e459fd0');
    
    this.headersForm = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    this.headersForm = this.headersForm.set('Ocp-Apim-Trace', 'true');
    this.headersForm = this.headersForm.set('Ocp-Apim-Subscription-Key', '27036eaec9514423bd062f500e459fd0');

    this.lp_apipHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    this.lp_apipHeaders = this.lp_apipHeaders.set('Ocp-Apim-Trace', 'true');
    this.lp_apipHeaders = this.lp_apipHeaders.set('Ocp-Apim-Subscription-Key', '27036eaec9514423bd062f500e459fd0');
        
    this.zapierHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    this.zapierHeaders = this.zapierHeaders.set('Ocp-Apim-Trace', 'true');
    this.zapierHeaders = this.zapierHeaders.set('Ocp-Apim-Subscription-Key', '27036eaec9514423bd062f500e459fd0');
    
    this.sfHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    this.sfHeaders = this.sfHeaders.set('Accept', 'application/json; indent=4');
    this.sfHeaders = this.sfHeaders.set('Ocp-Apim-Trace', 'true');
    this.sfHeaders = this.sfHeaders.set('Ocp-Apim-Subscription-Key', '27036eaec9514423bd062f500e459fd0');
  }
  

  getNtsUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(environment.lp_apip + '/common/GetNtsUserInfo/' + 'core_13_' + this.userName, { headers: this.lp_apipHeaders })
  }
  getAuthToken (): Observable<string> {
    return this.http.post<string>(
        environment.seafileBaseUrl + '/auth-token/', this.body, { headers: this.sfHeaders }
    );
  }
  
}
