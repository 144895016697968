import { HttpHeaders } from '@angular/common/http';

export const environment = {
    production: false,
    hmr: false,
    appConfig: 'appconfig.json',

    //EXISTING
    appId                : 3,
    db                   : 'LMC_COMMON',
    baseAdr2             : 'https://api.unitek.services/lp',
    remoteApiBaseUrl     : 'https://wc-api.ntslabs-unitek.com/',
    remoteApiHeaders     : new HttpHeaders().set('Content-Type', 'application/json').set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    azureBlobHeaders     : new HttpHeaders().set('Content-Type', 'image/webp').set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    filemanagerapi       : 'https://file.nts-launchpad.com/api/',
    filemanagerApiHeaders: new HttpHeaders().set('Content-Type', 'application/json').set('ApiKey', 'c59852a2-7f08-49e2-8541-e66c6e9be235'),
    //lp_apip            : 'https://apip.unitek.services/apit/api', //! TEST
    lp_apip              : 'https://apip.unitek.services/apip/api', //! PROD
    //lp_apip            : 'https://localhost:44390/api', //! LOCAL
    baseAdrNode          : 'https://fileuploader.unitek.io',
    baseAdrNodeStatic    : 'https://fileuploader.unitek.io/static',
    mailgunBase          : 'https://api.unitek.services/mg',
    postmarkBaseUrl      : 'https://api.unitek.services/pmk-lmc',
    postmarkKey          : 'fe430e7d-fc89-421b-ac2e-47b6149f6e08',
    zapierBaseUrl        : 'https://apip.unitek.services/z',
    webdavBaseUrl        : 'https://box.nts-unitek.com/remote.php/dav/files/lpad',
    seafileBaseUrl       : 'https://apip.unitek.services/seafile',
    seafileToken         : 'Token f1f68aa7b7391076027c68183dd6fae3b2fa18af',
    seafileAdmin         : 'lp-admin@nts-unitek.com',
    azureAccount         : 'unitekstorage',
    azureAccountKey      : 'zS6M4lGWqU4/aKetaoUtAHTqY4G9BtQKt4p7o6yZc6pIx7k0ohRbg7KZVAWnbwVZe10xuHSzPyFn8YQX4hl+QA==',
    body                 : { "username": "lpadmin", "password": "Nts1234!" },
  
    // NEW
    xApiKey: 'ca03na188ame03u1d78620de67282882a84',
    //apiBaseUrl: 'http://wc-api-13.ntslabs-unitek.local:4600',
    //apiBaseUrl: 'https://wc-api.ntslabs-unitek.net',
    apiBaseUrl: 'https://wc-api-13.ntslabs-unitek.com',
    apiHeaders: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    axHeaders: { 'content-type': 'application/json', 'x-api-key': 'ca03na188ame03u1d78620de67282882a84' },
    axMultiPartFormHeaders: {
        //'content-type': 'multipart/form-data; boundary=---011000010111000001101001',
        'x-api-key': 'ca03na188ame03u1d78620de67282882a84',
    },
    multiPartApiHeaders: new HttpHeaders().set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),
    azureMultiPartApiHeaders: new HttpHeaders().set(
        'x-functions-key',
        'FoUdP_hKo1uVFOZeEVwXCTnjRhTHgPcmy_LNR3HcH3BvAzFuOnvGBg==',
    ),
    mockApiBaseUrl: 'https://wc-mock-api.ntslabs-unitek.dev',
    mockApiHeaders: new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-api-key', 'ca03na188ame03u1d78620de67282882a84x'),

};
